.back-to-home {
  margin-top: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  z-index: 2;
}

.learnmore-page {
  /* background-image: url('/assets/reports_background.png'); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}



.container {
  width: 80%;
  max-width: 800px;
  margin: 30px auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}

.about-the-sensor {
  text-align: left;
}

.sensor-text {
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}

h1 {
  color: #2c3e50;
  margin-bottom: 25px;
  text-align: center;
}