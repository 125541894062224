.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* Optional semi-transparent overlay for better readability */


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative; /* Ensure content stays above overlay */
  z-index: 2;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.text {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
}

.underline {
  width: 60px;
  height: 4px;
  background-color: #4a90e2;
  margin: 10px auto 0;
  border-radius: 2px;
}

.inputs {
  width: 100%;
  margin-bottom: 20px;
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.input img {
  width: 20px;
  height: 20px;
  margin: 0 15px;
}

.input input {
  flex: 1;
  height: 100%;
  border: 20px;
  outline: none;
  font-size: 16px;
  padding: 0 10px;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
  font-size: 14px;
  color: #4a90e2;
  cursor: pointer;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}

.submit-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.submit {
  width: 48%;
  padding: 12px 0;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.submit:hover {
  background-color: #3a7bc8;
}

.gray {
  background: #EAEAEA;
  color: #676767;
}

.back-to-home {
  margin-top: 20px;
  text-align: center;
  color: #4a90e2;
  cursor: pointer;
  font-size: 14px;
}

.address-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.address-row .input {
  width: 48%;
  margin-bottom: 0;
}

.container {
  max-width: 450px; /* Increased to accommodate more fields */
}

.inputs {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 5px;
}