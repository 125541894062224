/* Dashboard Container */

.dashboard-page {
  background-image: url('../assets/background.png');
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: sans-serif;
  }
  
  /* Dashboard Header */
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .dashboard-header h1 {
    margin: 0;
    color: #333;
    font-size: 28px;
  }
  
  .user-welcome {
    font-size: 16px;
    color: #666;
  }
  
  .back-button {
    background-color: #f0f0f0;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .back-button:hover {
    background-color: #e0e0e0;
  }
  
  /* Dashboard Tabs */
  .dashboard-tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 25px;
  }
  
  .dashboard-tabs button {
    padding: 12px 25px;
    background: none;
    border: none;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: 500;
    color: #555;
  }
  
  .dashboard-tabs button.active {
    border-bottom: 3px solid #4a90e2;
    color: #4a90e2;
  }
  
  .dashboard-tabs button:hover:not(.active) {
    background-color: #f5f5f5;
    color: #333;
  }
  
  /* Dashboard Content */
  .dashboard-content {
    min-height: 400px;
  }
  
  /* Loading State */
  .dashboard-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4a90e2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Error State */
  .dashboard-error {
    text-align: center;
    padding: 40px;
    color: #e74c3c;
  }
  
  .dashboard-error h2 {
    margin-top: 0;
  }
  
  .error-actions {
    margin-top: 20px;
  }
  
  .error-actions button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Profile Tab */
  .profile-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    padding: 25px;
  }
  
  .profile-details {
    margin-top: 20px;
  }
  
  .profile-field {
    margin-bottom: 15px;
  }
  
  .profile-field label {
    display: block;
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
  }
  
  .address-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .address-info p {
    margin: 5px 0;
  }
  
  /* Devices Tab */
  .devices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
  }
  
  .device-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }
  
  .device-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .device-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .device-status {
    padding: 4px 10px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .device-status.active {
    background-color: #d4edda;
    color: #155724;
  }
  
  .device-status.inactive {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .device-details {
    margin-top: 15px;
  }
  
  .detail-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .detail-item span {
    color: #777;
  }
  
  /* Licenses Tab */
  .licenses-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
  }
  
  .license-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }
  
  .license-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .license-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .license-badge {
    padding: 4px 10px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .license-badge.free {
    background-color: #cce5ff;
    color: #004085;
  }
  
  .usage-bar-container {
    margin-top: 20px;
  }
  
  .usage-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .usage-bar {
    height: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .usage-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease;
  }
  
  /* Purchases Tab */
  .orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .order-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .order-title h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
  }
  
  .order-date {
    color: #777;
    font-size: 14px;
  }
  
  .order-amount {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .order-items {
    margin: 20px 0;
  }
  
  .order-items h4 {
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .items-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .items-table th,
  .items-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .items-table th {
    font-weight: 500;
    color: #555;
  }
  
  .order-status {
    color: #555;
  }
  
  /* No Data Message */
  .no-data-message {
    text-align: center;
    padding: 40px;
    color: #777;
    font-style: italic;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dashboard-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    
    .dashboard-tabs {
      flex-wrap: wrap;
    }
    
    .dashboard-tabs button {
      flex: 1;
      padding: 10px;
      font-size: 14px;
    }
    
    .devices-grid,
    .licenses-grid {
      grid-template-columns: 1fr;
    }
    
    .items-table {
      font-size: 14px;
    }
  }